<template>
  <UsersLayout>
    <UsersForm>
      <template #header>
        <h1 class="users-form__header-title">
          {{ $t('login.title') }}
        </h1>

        <h3 class="users-form__header-subtitle">
          {{ $t('login.subtitle') }}
        </h3>

        <Banner
          :hidden="!showBanner"
          :type="bannerType"
          :message="error || $t('errors.SIGN_IN_INVALID_CREDENTIALS')"
        />
      </template>

      <div class="row">
        <div class="col-12">
          <Input
            v-model="form.email"
            :placeholder="$t('email')"
            :errored="!validEmail && displayInputValidations"
            :required="true"
            type="text"
            data-test="login_email"
          />
          <Input
            v-model="form.password"
            :placeholder="$t('password')"
            :errored="!validPassword && displayInputValidations"
            :required="true"
            type="password"
            data-test="login_password"
          />
          <div class="login__form-footer d-md-flex d-block">
            {{ $t('login.password_hint') }}

            <router-link
              :to="{ name: 'ForgottenPassword' }"
            >
              <SkOroraButton
                variant="tertiary"
                size="small"
              >
                {{ $t('forgotten_password.title') }}
              </SkOroraButton>
            </router-link>
          </div>

          <div class="login__remember-me">
            <CheckBox
              v-model="form.rememberMe"
              class="login__remember-me__checkbox"
            />
            {{ $t('login.remember_me') }}
          </div>

          <SubmitBtn
            :text="$t('login.button')"
            :spinner="loadingInProgress"
            class="login__submit-btn"
            data-test="login_submit"
            @click="submitLogin"
          />
        </div>
      </div>

      <template #footer>
        <p>
          {{ $t('login.no_connection_yet') }}
          <SkOroraLink
            href="https://intercom.help/skello/fr/articles/3322800-connectez-vous-pour-la-premiere-fois-a-skello"
            class="d-md-none"
            target="_blank"
          >
            {{ $t('login.click_here') }}
          </SkOroraLink>
        </p>

        <SkOroraLink
          href="https://intercom.help/skello/fr/articles/3322800-connectez-vous-pour-la-premiere-fois-a-skello"
          class="login__link--gray d-none d-md-block"
          target="_blank"
        >
          {{ $t('login.click_here') }}
        </SkOroraLink>
      </template>
    </UsersForm>
  </UsersLayout>
</template>

<script>
// Utils
import {
  isEmail,
  isPresent,
} from '@static-app/shared/utils/validators';
import { httpClient } from '@skello-utils/clients';
import { authClient } from '@skello-utils/clients/auth_client';

// Components
import {
  SubmitBtn,
  Banner,
  Input,
  CheckBox,
} from '@static-ui';
import {
  SkOroraButton,
  SkOroraLink,
} from '@skelloapp/skello-ui';
import UsersForm from '../shared/components/Form';
import UsersLayout from '../shared/components/Layout';

export default {
  name: 'Login',
  components: {
    Banner,
    CheckBox,
    Input,
    SkOroraButton,
    SkOroraLink,
    SubmitBtn,
    UsersForm,
    UsersLayout,
  },
  async beforeRouteEnter(_to, _from, next) {
    /* NOTE: This code block solves the following issue:
    ** When a user hits refresh, we cannot (yet) send the JWT's in headers,
    ** so we rely on a cookie (see Warden::Strategies::CookiesFallbackAuth).
    ** In some cases the user cannot be authenticated, and as we still have multiple Vue apps
    ** pages controller will end up rendering this vue App.
    ** So we make a request with the required headers in order to reauthenticate and then:
    ** - on success (200): use the initialy requested path (see V3::PagesController#refresh_me)
    ** - no content (204) : use the user home url (V3::PagesController#home_url)
    ** - otherwise the user is not authenticated so we just display the login
    */
    try {
      const authToken = await authClient.getAuthToken();
      if (!authToken) { next(); }

      const refreshMeResponse = await httpClient.get('/v3/refresh_me');
      if (refreshMeResponse.status === 200) {
        window.location.replace(refreshMeResponse.data.url);
      } else if (refreshMeResponse.status === 204) {
        const homeUrlresponse = await httpClient.get('/v3/home_url');
        window.location.replace(homeUrlresponse.data.url);
      } else {
        next();
      }
    } catch (_e) { next(); }
  },
  data() {
    return {
      form: {
        email: '',
        password: '',
        rememberMe: false,
      },
      showBanner: false,
      displayInputValidations: false,
      loadingInProgress: false,
      error: null,
      bannerType: 'error',
    };
  },

  computed: {
    validEmail() {
      return isEmail(this.form.email);
    },
    validPassword() {
      return isPresent(this.form.password, 8);
    },
    validForm() {
      return this.validEmail && this.validPassword;
    },
    isSvcUserFFEnabled() {
      return window.featureFlag.FEATUREDEV_SVC_USERS;
    },
  },

  mounted() {
    window.addEventListener('keyup', this.handleKeyup);

    if (this.$router.currentRoute.query.account_validated === 'true') {
      this.bannerType = 'error';
      this.showBanner = true;
      this.error = this.$t('errors.account_already_validated');
    }

    if (this.$router.currentRoute.query.account_validated_from_self_serve === 'true') {
      this.bannerType = 'info';
      this.showBanner = true;
      this.error = this.$t('errors.account_already_validated_from_self_serve');
    }

    if (this.$router.currentRoute.query.destroy_session === 'true') {
      authClient.destroySession();
    }
  },

  beforeDestroy() {
    window.removeEventListener('keyup', this.handleKeyup);
  },

  methods: {
    handleKeyup(event) {
      // press enter
      if (event.keyCode === 13) {
        this.submitLogin();
      }
    },

    submitLogin() {
      this.displayInputValidations = true;
      if (!this.validForm) {
        this.showBanner = true;
        return;
      }

      this.loadingInProgress = true;
      authClient
        .login(this.form)
        .then(_response => {
          this.showBanner = false;
          this.reloadPage();
        })
        .catch(({ key, token }) => {
          this.loadingInProgress = false;

          if (key === 'SIGN_IN_USER_UNCONFIRMED' && token) {
            window.location = `/v3/user-creation?token=${token}`;
          } else if (key === 'SIGN_IN_USER_UNCONFIRMED_V5') {
            window.location = `/v3/self_serve/step_two?token=${token}`;
          } else {
            this.bannerType = 'error';
            this.showBanner = true;
            this.error = this.$t(`errors.${key || 'default'}`);
          }
        });
    },
    reloadPage() {
      if (this.isSvcUserFFEnabled) {
        this.useHomeRedirectorInstead();
      } else {
        window.location.reload(true);
      }
    },
    useHomeRedirectorInstead() {
      httpClient.get('/v3/home_url').then(response => {
        window.location.replace(response.data.url);
      }).catch(() => {
        // Apply the behavior without middleware for jwts and
        // Reload page and let HomeRedirector redirect user
        window.location.reload(true);
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.login__form-footer {
  width: 100%;
  font-size: $fs-text-m;
  line-height: 120%;
  color: #000;
  justify-content: space-between;
  text-align: left;
  margin-bottom: 28px;
}

.login__remember-me {
  width: 100%;
  font-size: $fs-text-m;
  margin-bottom: 40px;
  display: flex;
  align-items: center;
}

.login__remember-me__checkbox {
  margin-right: 15px;
}

.login__link--gray {
  text-decoration: underline;
  color: $sk-grey;

  &:hover,
  &:focus,
  &:active {
    color: $sk-black;
    border: none;
    text-decoration: underline;
  }
}
</style>
