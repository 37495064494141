<template>
  <div id="main-body">
    <div
      id="main-content"
      class="container"
    >
      <SmartAppBanner v-if="isMobile" />
      <router-view />
    </div>
    <SkOroraBackdrop />
    <CookieDialog />
  </div>
</template>

<script>
import '@static-app/shared/assets/scss/_styles.scss';
import { SkOroraBackdrop } from '@skelloapp/skello-ui';
import { httpClient } from '@skello-utils/clients';
import { setSvcUsersFeatureFlags } from '@skello-utils/feature-flags';

import CookieDialog from '@app-js/shared/components/CookieDialog/CookieDialog';
import { isMobile } from '@skello-utils/mobile.js';
import SmartAppBanner from '@app-js/shared/components/SmartAppBanner/SmartAppBanner';

export default {
  name: 'V3Static',
  components: {
    CookieDialog,
    SmartAppBanner,
    SkOroraBackdrop,
  },
  data() {
    return {
      isMobile: isMobile(),
    };
  },
  mounted() {
    const navigatorLang = navigator.language || navigator.userLanguage;
    const urlParamsLang = new URLSearchParams(window.location.search).get('lang');
    this.$i18n.locale = urlParamsLang || navigatorLang.substring(0, 2);

    this.setSvcUsersFeatureFlags();
  },
  methods: {
    async setSvcUsersFeatureFlags() {
      const { data } = await httpClient.get('/v3/api/feature_flags');

      setSvcUsersFeatureFlags(data);
    },
  },
};
</script>

<style lang="scss">
#main-content {
  height: 100%;
  min-width: 100%;
  margin: 0;
  padding: 0;
  background-color: white;
}
</style>
